module.exports = {
  HOME: '首頁',
  WALLET: '錢包',
  SERVICE: '客服',
  MY: '我的',
  APPLYMONEY: '申請金額(元)',
  AWAITJIE: '待還金額(元)',
  UNIT: '元',
  BORRORWMONEYTIME: '借款期限',
  EVERYISSUE: '每期還款',
  DAYRATE: '月利率',
  MOTHLYINTEREST: '月利息',
  CONSENT: '同意',
  AGREEMENTENT: '《借款協議》',
  ATONECBORROWMONEY: '立即借款',
  CONSENTPROTOCOL: '請你同意並且勾選協議',
  LOANAPPLICATION: '申請借款',
  CONSENTBORROWMESSAGE: '確認借款信息',
  BORRORWMONEY: '借款金額',
  ACCOUNTBANK: '到賬銀行',
  PAYEEBOX: '收款賬號',
  SUBMITBORROWTEXT: '提交借單',
  PLEACHECKPROTOCOL: '請勾選本協議',
  MONTHS: '個月',
  USERNAME: '用戶名',
  PASSWORD: '密碼',
  SUBMIT: '提交',
  CHECKUSERNMAE: '用戶名不能為空',
  CHECKPASSWORD: '密碼不能為空',
  REGISTER: '註冊',
  FORGET: '忘記密碼',
  NOTCHINA: '不能用中文',
  LIJIRECHARGE: '立即充值',
  CANCEL: '取消',
  RECHARGEMONEY: '充值金額',
  WITHDRAWDEPOSIT: '提領金額',
  WITHDRAWDEPOSITPASSWORD: '提領密碼',
  INPUTWITHDRAWDEPOSITPASSWORD: '請輸入提領密碼',
  WITHDRAWDEPOSITMONEYNULL: '提領金額不能為空',
  WITHDRAWDEPOSITNULL: '提領密碼不能為空',
  MYDATA: '我的資料',
  MYBORROW: '我的借款',
  MYREFUND: '我的還款',
  OPENVIP: '開通VIP',
  CHANGEPASSWORD: '修改密碼',
  LOGOUT: '退出登錄',
  MSUTDATAFULL: '以下為必填資料填寫完善才能申請借款',
  IDENTITYDATA: '身份信息',
  UNDERSTANDBASICINFORMATION: '讓我們瞭解您的基本情況',
  DATAMESSAGE: '資料信息',
  RECEIPTBANKCARD: '收款銀行卡',
  PHONENUMVERIFY: '手機號碼驗證',
  VERIFYSELFPHONENUM: '認證您本人的手機號',
  INCREASETHEPASSRATE: '以下為選填補充資料可增加額度和審核通過幾率',
  FURTHERINFORMATION: '補充資料',
  REVIEWTHEPASSRATE: '增加您的審核通過幾率',
  FILLED: '已填寫',
  FILLINTRUEINFORMATION: '填寫真實有效的信息，審核才會通過哦~',
  NAME: '名字',
  IDCARDNUMBER: '身份證號碼',
  UPLOADIDPROTO: '上傳身份證照片',
  IDPOSITIVEPROTO: '身份證正面照片',
  IDONTHEBRECKPROTO: '身份證背面照片',
  HANDTAKEIDPROTO: '手拿身份證照片',
  ENTITYNAME: '公司名稱',
  POSITION: '職位',
  ENTITYPHONE: '公司電話',
  WORKAGE: '工齡',
  CURRENTRESIDENCEDETAILS: '戶籍居住地址',
  CONTACTOFIMMEDIATEFAMILY: '直系親屬聯繫人',
  CONTACTNAME: '姓名',
  CONTACTPHONE: '手機號',
  CONTACTRELATION: '關係',
  OTHER: '其他聯繫人',
  TAKECARDNAME: '持卡人的姓名',
  TAKEID: '持卡人的身份證',
  BANKNAME: '開戶銀行',
  BANKCRAD: '銀行卡號',
  BANKCRADSELF: '溫馨提示：填寫的銀行卡須是本人名下的借記卡（儲蓄卡）。',
  INPUTBANKCARDNUMBER: '請輸入銀行卡卡號',
  SAVEDATA: '添加資料',
  BORRORWDETAIL: '借款詳情',
  BORRORWPLAN: '借款進度',
  SUBMITSUCCEED: '提交成功',
  ARRIVEMONEYSUCCEED: '到賬成功',
  ORDERFORMNUM: '訂單編號',
  REFUNDSTATE: '還款說明',
  NEEDNOTBORRORW: '無需還款哦！',
  CURRENTMONTHNEEDNOTBORRORW: '您本月暫無需要還款賬單',
  NOTE: '短信驗證',
  NOTENUM: '短信驗證碼',
  NWESPASSWORD: '新密碼',
  INPUTPHONE: '請輸入手機號',
  INPUTNOTE: '請輸入短信驗證碼',
  INPUTNEWSPASSWORD: '請設置6-16位密碼',
  CONFIRM: '確定',
  PLEAINPUTNAME: '請輸入姓名',
  PLEAINPUTID: '請輸入身份證號碼',
  PLEAENTITYNAME: '請輸入公司名稱',
  PLEAPOSITION: '請輸入職位',
  PLEAENTITYPHONE: '請輸入公司電話',
  PLAEAWORKAGE: '請輸入工作年齡',
  PLEACURRENTRESIDENCEDETAILS: '請輸入戶籍居住地址',
  PLEACONTACTRELATION: '例如：父母 子女',
  BORRORSERIALBUNBER: '借款編號',
  TOTAILMONEY: '總金額',
  RECHARGEMONEYNONTNULL: '充值金額不能為空',
  RECHARGE: '充值詳情',
  UPLOADVOUCHER: '上傳憑證',
  UPLOADONEPROTO: '上傳一張圖片',
  USERREGISTER: '用戶註冊',
  FORGETPASSWORD: '找回密碼',
  NOTNULL: '不能為空',
  LENGTH10: '長度不能小於10',
  REGISTEROK: '註冊成功',
  LOGINOK: '登陸成功',
  AWAIT: '等待中',
  THEACCOUNTBALANCE: '賬戶餘額',
  UNFILLED: '未填寫',
  UNITADDRESS: '公司地址',
  INPUTUNITADDRESS: '請輸入公司地址',
  OKBORRORW: '成功借款',
  PLEAMESSAGECOMPLETE: '請把信息寫完整',
  WORKCERTIFICATE: '工作證明',
  INCOMEPROOF: '收入證明',
  NOBRORROW: '沒有借款',
  MYWITHDRAWDEPOSIT: '我的提領',
  NOMYWITHDRAWDEPOSIT: '沒有提領信息',
  MYWITHDRAWDEPOSITIME: '提領時間',
  MYWITHDRAWDEPOSITMONEY: '提領金額',
  MYWITHDRAWDEPOSITDETAILS: '提領詳情',
  VIP: '會員',
  LINK: '鏈接',
  STUTA: '狀態',
  MESSAGE: '消息',
  MYWITHDRAWDEPOSILONG: '提領進度',
  LOADAPP: '下載APP',
  LIJITIXIAN: '立即提領',
  TIXIANID: '提領編號',
  WARMPROMPT: '溫馨提示',
  CONTACTME: '聯繫我',
  QUESTION: '問題解答',
  TOFAILURE: '到賬失敗',
  WENXINGTISHI: '貸款申請正在進行中，請稍後我們會盡快通知您！',
  OPENVVIP: '開通會員',
  SEND_SMS: '發送驗證碼',
  CUSTOMERSERVICECENTER: '客服中心',
  BORROWINGTIME: '借款時間',
  IMGNOT: '圖片不能為空',
  PLEALOGIN: '請登錄',
  Pleasereviewtheprotocolcarefully: '請仔細查看協議',
  Lookatthecontract: '查看合同',
  FILLINALL: '請填寫全部',
  WDNUMBER: '提領密碼',
  PWDWDNUMBER: '請輸入提領碼',
  EDITTHEAMOUNT: '編輯金額',
  THENEWPASSWORD: '請輸入新密碼',
  MODIFYTHESUCCESS: '修改成功',
  MODIFYTHEFAILURE: '修改失敗',
  CONTACTCUSTOMERSERVICE: '聯繫客服',
  TIX: '提領',
  CHECKPASSWORDRe: '確認密碼',
  TWICE: '兩次輸入密碼不一致',
  HT: '合同',
  login_btn: '登入',
  sign: '簽名',
  look_xieyi: '查看協定'
}
