module.exports = {
  HOME: '首页',
  WALLET: '钱包',
  SERVICE: '客服',
  MY: '我的',
  APPLYMONEY: '申请金额(元)',
  AWAITJIE: '待还金额(元)',
  UNIT: '元',
  BORRORWMONEYTIME: '借款期限',
  EVERYISSUE: '每期还款',
  DAYRATE: '月利率',
  MOTHLYINTEREST: '月利息',
  CONSENT: '同意',
  AGREEMENTENT: '《借款协议》',
  ATONECBORROWMONEY: '立即借款',
  CONSENTPROTOCOL: '请你同意并且勾选协议',
  LOANAPPLICATION: '申请借款',
  CONSENTBORROWMESSAGE: '确认借款信息',
  BORRORWMONEY: '借款金额',
  ACCOUNTBANK: '到账银行',
  PAYEEBOX: '收款账号',
  SUBMITBORROWTEXT: '提交借单',
  PLEACHECKPROTOCOL: '请勾选本协议',
  MONTHS: '个月',
  USERNAME: '用户名',
  PASSWORD: '密码',
  SUBMIT: '提交',
  CHECKUSERNMAE: '用户名不能为空',
  CHECKPASSWORD: '密码不能为空',
  REGISTER: '注册',
  FORGET: '忘记密码',
  NOTCHINA: '不能用中文',
  LIJIRECHARGE: '立即充值',
  CANCEL: '取消',
  RECHARGEMONEY: '充值金额',
  WITHDRAWDEPOSIT: '提现金额',
  WITHDRAWDEPOSITPASSWORD: '提现密码',
  INPUTWITHDRAWDEPOSITPASSWORD: '请输入提现密码',
  WITHDRAWDEPOSITMONEYNULL: '提现金额不能为空',
  WITHDRAWDEPOSITNULL: '提现密码不能为空',
  MYDATA: '我的资料',
  MYBORROW: '我的借款',
  MYREFUND: '我的还款',
  OPENVIP: '开通VIP',
  CHANGEPASSWORD: '修改密码',
  LOGOUT: '退出登录',
  MSUTDATAFULL: '以下为必填资料填写完善才能申请借款',
  IDENTITYDATA: '身份信息',
  UNDERSTANDBASICINFORMATION: '让我们了解您的基本情况',
  DATAMESSAGE: '资料信息',
  RECEIPTBANKCARD: '收款银行卡',
  PHONENUMVERIFY: '手机号码验证',
  VERIFYSELFPHONENUM: '认证您本人的手机号',
  INCREASETHEPASSRATE: '以下为选填补充资料可增加额度和审核通过几率',
  FURTHERINFORMATION: '补充资料',
  REVIEWTHEPASSRATE: '增加您的审核通过几率',
  FILLED: '已填写',
  FILLINTRUEINFORMATION: '填写真实有效的信息，审核才会通过哦~',
  NAME: '名字',
  IDCARDNUMBER: '身份证号码',
  UPLOADIDPROTO: '上传身份证照片',
  IDPOSITIVEPROTO: '身份证正面照片',
  IDONTHEBRECKPROTO: '身份证背面照片',
  HANDTAKEIDPROTO: '手拿身份证照片',
  ENTITYNAME: '单位名称',
  POSITION: '职位',
  ENTITYPHONE: '单位电话',
  WORKAGE: '工龄',
  CURRENTRESIDENCEDETAILS: '现居详细地址',
  CONTACTOFIMMEDIATEFAMILY: '直系亲属联系人',
  CONTACTNAME: '姓名',
  CONTACTPHONE: '手机号',
  CONTACTRELATION: '关系',
  OTHER: '其他联系人',
  TAKECARDNAME: '持卡人的姓名',
  TAKEID: '持卡人的身份证',
  BANKNAME: '开户银行',
  BANKCRAD: '银行卡号',
  BANKCRADSELF: '温馨提示：填写的银行卡须是本人名下的借记卡（储蓄卡）。',
  INPUTBANKCARDNUMBER: '请输入银行卡卡号',
  SAVEDATA: '添加资料',
  BORRORWDETAIL: '借款详情',
  BORRORWPLAN: '借款进度',
  SUBMITSUCCEED: '提交成功',
  ARRIVEMONEYSUCCEED: '到账成功',
  ORDERFORMNUM: '订单编号',
  REFUNDSTATE: '还款说明',
  NEEDNOTBORRORW: '无需还款哦！',
  CURRENTMONTHNEEDNOTBORRORW: '您本月暂无需要还款账单',
  NOTE: '短信验证',
  NOTENUM: '短信验证码',
  NWESPASSWORD: '新密码',
  INPUTPHONE: '请输入手机号',
  INPUTNOTE: '请输入短信验证码',
  INPUTNEWSPASSWORD: '请设置6-16位密码',
  CONFIRM: '确定',
  PLEAINPUTNAME: '请输入姓名',
  PLEAINPUTID: '请输入身份证号码',
  PLEAENTITYNAME: '请输入单位名称',
  PLEAPOSITION: '请输入职位',
  PLEAENTITYPHONE: '请输入单位电话',
  PLAEAWORKAGE: '请输入工作年龄',
  PLEACURRENTRESIDENCEDETAILS: '请输入现居详细地址',
  PLEACONTACTRELATION: '例如：父母 子女',
  BORRORSERIALBUNBER: '借款编号',
  TOTAILMONEY: '总金额',
  RECHARGEMONEYNONTNULL: '充值金额不能为空',
  RECHARGE: '充值详情',
  UPLOADVOUCHER: '上传凭证',
  UPLOADONEPROTO: '上传一张图片',
  USERREGISTER: '用户注册',
  FORGETPASSWORD: '找回密码',
  NOTNULL: '不能为空',
  LENGTH10: '长度不能小于10',
  REGISTEROK: '注册成功',
  LOGINOK: '登陆成功',
  AWAIT: '等待中',
  THEACCOUNTBALANCE: '账户余额',
  UNFILLED: '未填写',
  UNITADDRESS: '单位地址',
  INPUTUNITADDRESS: '请输入单位地址',
  OKBORRORW: '成功借款',
  PLEAMESSAGECOMPLETE: '请把信息写完整',
  WORKCERTIFICATE: '工作证明',
  INCOMEPROOF: '收入证明',
  NOBRORROW: '没有借款',
  MYWITHDRAWDEPOSIT: '我的提现',
  NOMYWITHDRAWDEPOSIT: '没有提现信息',
  MYWITHDRAWDEPOSITIME: '提现时间',
  MYWITHDRAWDEPOSITMONEY: '提现金额',
  MYWITHDRAWDEPOSITDETAILS: '提现详情',
  VIP: '会员',
  LINK: '链接',
  STUTA: '状态',
  MESSAGE: '消息',
  MYWITHDRAWDEPOSILONG: '提现进度',
  LOADAPP: '下载APP',
  LIJITIXIAN: '立即提现',
  TIXIANID: '提现编号',
  WARMPROMPT: '温馨提示',
  CONTACTME: '联系我',
  QUESTION: '问题解答',
  TOFAILURE: '到账失败',
  WENXINGTISHI: '温馨提示显示，审核中，开通会员加速审核',
  OPENVVIP: '开通会员',
  SEND_SMS: '发送验证码',
  CUSTOMERSERVICECENTER: '客服中心',
  BORROWINGTIME: '借款时间',
  IMGNOT: '图片不能为空',
  PLEALOGIN: '请登录',
  Pleasereviewtheprotocolcarefully: '请仔细查看协议',
  Lookatthecontract: '查看合同',
  FILLINALL: '请填写全部',
  WDNUMBER: '提现码',
  PWDWDNUMBER: '请输入提现码',
  EDITTHEAMOUNT: '编辑金额',
  THENEWPASSWORD: '请输入新密码',
  MODIFYTHESUCCESS: '修改成功',
  MODIFYTHEFAILURE: '修改失败',
  CONTACTCUSTOMERSERVICE: '联系客服',
  TIX: '提现',
  CHECKPASSWORDRe: '确认密码',
  TWICE: '两次输入密码不一致',
  HT: '合同',
  login_btn: '登入',
  sign: '签名',
  look_xieyi: '查看协议'
}
